// assets
import PetsIcon from "@mui/icons-material/Pets";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const inventory = {
  id: "inventory",
  title: "Inventory",
  type: "group",
  permission: "Inventory",
  children: [
    {
      id: "cattle",
      title: "Cattle",
      type: "item",
      url: "/admin/cattle",
      icon: PetsIcon,
      breadcrumbs: false,
      permission: "Cattle",
    },
    {
      id: "retailPack",
      title: "Retail Pack",
      type: "item",
      url: "/admin/retailPack",
      icon: ArtTrackIcon,
      breadcrumbs: false,
      permission: "RetailPack",
    },
  ],
};

export default inventory;
