// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "../../../../../menu";
import { EntityContext } from "../../../../../App";
import { useContext } from "react";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { can } = useContext(EntityContext);

  const navItems = menuItem.items
    .filter((item) => {
      if (!item.permission) return true;
      return can(item.permission);
    })
    .map((item) => {
      const children = item.children.filter((child) => {
        if (!child.permission) return true;
        return can(child.permission);
      });
      return { ...item, children };
    })
    .map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
  return <>{navItems}</>;
};

export default MenuList;
