import { useCallback, useContext } from "react";
import { httpFetch } from "../../services/AdminApiService";
import { AuthContext, fp } from "../../App";

const useAccountService = () => {
  const { authState } = useContext(AuthContext);

  const myAccount = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/myAccount", config);
  }, [authState.token]);

  const findPermissions = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/myAccount/findPermissions", config);
    },
    [authState.token]
  );

  const findAll = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/accounts", config);
  }, [authState.token]);

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/accounts", config);
    },
    [authState.token]
  );

  const findOne = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/accounts/" + id, config);
    },
    [authState.token]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/accounts/" + id, config);
    },
    [authState.token]
  );

  const destroy = useCallback(
    async (id) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/accounts/" + id, config);
    },
    [authState.token]
  );

  const addEntityWithRole = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/accounts/" + id + "/addEntityWithRole", config);
    },
    [authState.token]
  );

  const removeEntityWithRole = useCallback(
    async (id, params) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/accounts/" + id + "/removeEntityWithRole", config);
    },
    [authState.token]
  );

  return {
    myAccount,
    findPermissions,
    findAll,
    create,
    findOne,
    update,
    destroy,
    addEntityWithRole,
    removeEntityWithRole,
  };
};
export { useAccountService };
