// assets
import KitchenIcon from "@mui/icons-material/Kitchen";
import EventBusyIcon from "@mui/icons-material/EventBusy";

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const processing = {
  id: "processing",
  title: "Processing",
  type: "group",
  permission: "Processing",
  children: [
    {
      id: "slaughterRecord",
      title: "Slaughter Record",
      type: "item",
      url: "/admin/slaughterRecord",
      icon: KitchenIcon,
      breadcrumbs: false,
      permission: "Slaughter",
    },
    {
      id: "disposal",
      title: "Disposal Record",
      type: "item",
      url: "/admin/disposals",
      icon: EventBusyIcon,
      breadcrumbs: false,
      permission: "Disposal",
    },
  ],
};

export default processing;
