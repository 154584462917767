import React, { useState, useEffect, useContext, useReducer } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { createTheme } from "@mui/material/styles";
import NavigationScroll from "./components/layouts/NavigationScroll";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useSelector } from "react-redux";
import AuthService from "./services/AuthService";
import { useAccountService } from "./modules/account/useAccountService";
import Routes from "./routes";

export const fp = (async () => {
  const fp = await FingerprintJS.load();
  return await fp.get();
})();

export const AuthContext = React.createContext();

export const EntityContext = React.createContext();

const themeOptions = {
  direction: "ltr",
  // palette: themePalette(themeOption),
  mixins: {
    toolbar: {
      minHeight: "48px",
      padding: "16px",
      "@media (min-width: 600px)": {
        minHeight: "48px",
      },
    },
  },
  // typography: themeTypography(themeOption)
};

const theme = createTheme(themeOptions);

const initialState = {
  token: null,
  authUser: null,
  isAuthenticated: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        // ...state,
        isAuthenticated: true,
        authUser: action.payload.authUser,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        // ...state,
        isAuthenticated: false,
        authUser: null,
        token: null,
        isLogOut: true,
      };
    case "UPDATE":
      return {
        ...state,
        authUser: action.payload.authUser,
      };
    default:
      return state;
  }
};

function App() {
  const isServiceWorkerUpdated = useSelector((state) => state.serviceWorkerUpdated);
  const serviceWorkerRegistration = useSelector((state) => state.serviceWorkerRegistration);

  const [authState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    AuthService.checkAuth().then((res) => {
      if (res.status === "success") {
        dispatch({
          type: "LOGIN",
          payload: {
            authUser: res.data.auth,
            token: res.data.token,
          },
        });
      } else {
        AuthService.logout();
        dispatch({ type: "LOGOUT" });
        // go to login page
        // window.location.href = "/login";
      }
    });
  }, []);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        dispatch,
      }}
    >
      <Entity>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {isServiceWorkerUpdated && (
              <Alert
                severity="info"
                action={
                  <Button variant="outlined" size="small" onClick={updateServiceWorker}>
                    Update
                  </Button>
                }
              >
                There is a new version available
              </Alert>
            )}

            <Router>
              <NavigationScroll>
                <Routes />
              </NavigationScroll>
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </Entity>
    </AuthContext.Provider>
  );
}

const Entity = (props) => {
  const { authState } = useContext(AuthContext);

  const { myAccount, findPermissions } = useAccountService();

  const [loading, setLoading] = useState(true);
  const [accountEntity, setAccountEntity] = useState();
  const [activeEntity, setActiveEntity] = useState();
  const [entityList, setEntityList] = useState([]);
  const [authPermissions, setAuthPermissions] = useState([]);

  useEffect(() => {
    if (authState) {
      if (authState.isLogOut) {
        setLoading(false);
      }

      myAccount().then((res) => {
        if (res.status === "success") {
          setAccountEntity(res.data);

          if (res.data["Entity/_Users"] && res.data["Entity/_Users"].length > 0) {
            setEntityList(res.data["Entity/_Users"]);
            setActiveEntity(res.data["Entity/_Users"][0]);
          }
        } else {
          setActiveEntity(null);
          setLoading(false);
        }
      });
    }
  }, [authState]);

  useEffect(() => {
    if (activeEntity && activeEntity._id && accountEntity && accountEntity._id) {
      findPermissions({
        entityId: activeEntity._id,
        accountId: accountEntity._id,
      }).then((res) => {
        if (res.status === "success") {
          setAuthPermissions(res.data);
        }
        setLoading(false);
      });
    }
  }, [activeEntity, accountEntity]);

  const can = (route) => {
    if (authPermissions.includes(route)) {
      return true;
    }
    return false;
  };

  return (
    <EntityContext.Provider value={{ can, activeEntity, setActiveEntity, entityList }}>
      {/* if activeEntity is not ready, show loader */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <CircularProgress />
        </Box>
      ) : (
        // if activeEntity is ready, show children
        props.children
      )}
    </EntityContext.Provider>
  );
};
export default App;
