import { lazy } from "react";
import AdminLayout from "../components/layouts/admin/AdminLayout";
import Loadable from "../components/loadable/Loadable";

// Dashboard
const Dashboard = Loadable(lazy(() => import("../modules/dashboard/Dashboard")));
const Recall = Loadable(lazy(() => import("../modules/recall/Recall")));

// Stock movement routing
const StockIn = Loadable(lazy(() => import("../modules/stockIn/StockIn")));
const StockInDetail = Loadable(lazy(() => import("../modules/stockIn/StockInDetail")));

const StockOut = Loadable(lazy(() => import("../modules/stockOut/StockOut")));
const StockOutDetail = Loadable(lazy(() => import("../modules/stockOut/StockOutDetail")));

const StockReturn = Loadable(lazy(() => import("../modules/stockReturn/StockReturn")));
const StockReturnDetail = Loadable(lazy(() => import("../modules/stockReturn/StockReturnDetail")));

// Inventory
const Cattle = Loadable(lazy(() => import("../modules/cattle/Cattle")));
const CattleDetail = Loadable(lazy(() => import("../modules/cattle/CattleDetail")));
const RetailPack = Loadable(lazy(() => import("../modules/retailPack/RetailPack")));
const RetailPackDetail = Loadable(lazy(() => import("../modules/retailPack/RetailPackDetail")));

// Processing
const Disposal = Loadable(lazy(() => import("../modules/disposal/Disposal")));
const DisposalDetail = Loadable(lazy(() => import("../modules/disposal/DisposalDetail")));
const SlaughterRecord = Loadable(lazy(() => import("../views/abattoir/SlaughterRecord")));
const SlaughterRecordDetail = Loadable(lazy(() => import("../modules/slaughter/SlaughterRecordDetail")));

// Settings
const ProductSetting = Loadable(lazy(() => import("../modules/productSetting/ProductSetting")));
const Supplier = Loadable(lazy(() => import("../modules/supplier/Supplier")));
const CustomerOutlet = Loadable(lazy(() => import("../modules/customerOutlet/CustomerOutlet")));
const EntityStaff = Loadable(lazy(() => import("../modules/entityStaff/EntityStaff")));
const EntityVehicle = Loadable(lazy(() => import("../modules/entityVehicle/EntityVehicle")));
const TraceBatch = Loadable(lazy(() => import("../modules/traceBatch/TraceBatch")));
const TraceBatchDetail = Loadable(lazy(() => import("../modules/traceBatch/TraceBatchDetail")));

// sinisana routing
const Security = Loadable(lazy(() => import("../modules/security/Security")));
const Entity = Loadable(lazy(() => import("../modules/entity/Entity")));
const EntityDetail = Loadable(lazy(() => import("../modules/entity/EntityDetail")));
const Account = Loadable(lazy(() => import("../modules/account/Account")));
const AccountDetail = Loadable(lazy(() => import("../modules/account/AccountDetail")));
const FlureeQuery = Loadable(lazy(() => import("../modules/fluree/FlureeQuery")));
const FlureeTransact = Loadable(lazy(() => import("../modules/fluree/FlureeTransact")));

// account routing
const AccountSetting = Loadable(lazy(() => import("../views/account/AccountSetting")));

const Welcome = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Welcome to the Admin Dashboard</h1>
      <p>This is the admin dashboard. You can access the different sections of the application here.</p>
    </div>
  );
};

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = (can) => {
  return {
    path: "/",
    element: <AdminLayout />,
    children: [
      {
        path: "/admin/welcome",
        element: <Welcome />,
      },
      {
        path: "/admin/dashboard",
        element: can("Dashboard") ? <Dashboard /> : null,
      },
      {
        path: "/admin/stockIn/",
        element: can("StockIn") ? <StockIn /> : null,
      },
      {
        path: "/admin/stockIn/:id",
        element: can("StockIn") ? <StockInDetail /> : null,
      },
      {
        path: "/admin/stockOut/",
        element: can("StockOut") ? <StockOut /> : null,
      },
      {
        path: "/admin/stockOut/:id",
        element: can("StockOut") ? <StockOutDetail /> : null,
      },
      {
        path: "/admin/stockReturn/",
        element: can("StockReturn") ? <StockReturn /> : null,
      },
      {
        path: "/admin/stockReturn/:id",
        element: can("StockReturn") ? <StockReturnDetail /> : null,
      },
      {
        path: "/admin/cattle",
        element: can("Cattle") ? <Cattle /> : null,
      },
      {
        path: "/admin/cattle/:id",
        element: can("Cattle") ? <CattleDetail /> : null,
      },
      {
        path: "/admin/retailPack/",
        element: can("RetailPack") ? <RetailPack /> : null,
      },
      {
        path: "/admin/retailPack/:id",
        element: can("RetailPack") ? <RetailPackDetail /> : null,
      },
      {
        path: "/admin/disposals/",
        element: can("Disposal") ? <Disposal /> : null,
      },
      {
        path: "/admin/disposals/:id",
        element: can("Disposal") ? <DisposalDetail /> : null,
      },

      {
        path: "/admin/slaughterRecord",
        element: can("Slaughter") ? <SlaughterRecord /> : null,
      },
      {
        path: "/admin/slaughter/:id",
        element: can("Slaughter") ? <SlaughterRecordDetail /> : null,
      },
      {
        path: "/admin/settings/products",
        element: can("Settings") ? <ProductSetting /> : null,
      },
      {
        path: "/admin/settings/suppliers",
        element: can("Settings") ? <Supplier /> : null,
      },
      {
        path: "/admin/settings/customer",
        element: can("Settings") ? <CustomerOutlet /> : null,
      },
      {
        path: "/admin/settings/staff",
        element: can("Settings") ? <EntityStaff /> : null,
      },
      {
        path: "/admin/settings/vehicle",
        element: can("Settings") ? <EntityVehicle /> : null,
      },
      {
        path: "/admin/settings/traceBatches",
        element: can("TraceCode") ? <TraceBatch /> : null,
      },
      {
        path: "/admin/settings/traceBatches/:id",
        element: can("TraceCode") ? <TraceBatchDetail /> : null,
      },

      {
        path: "/admin/sinisana/entities",
        element: can("Sinisana") ? <Entity /> : null,
      },
      {
        path: "/admin/sinisana/entities/:id",
        element: can("Sinisana") ? <EntityDetail /> : null,
      },
      {
        path: "/admin/sinisana/accounts",
        element: can("Sinisana") ? <Account /> : null,
      },
      {
        path: "/admin/sinisana/accounts/:id",
        element: can("Sinisana") ? <AccountDetail /> : null,
      },
      {
        path: "/admin/sinisana/security",
        element: can("Sinisana") ? <Security /> : null,
      },
      {
        path: "/admin/recall",
        element: can("Recall") ? <Recall /> : null,
      },
      {
        path: "/admin/account/settings",
        element: <AccountSetting />,
      },
      {
        path: "/admin/fluree/query",
        element: can("Sinisana") ? <FlureeQuery /> : null,
      },
      {
        path: "/admin/fluree/transact",
        element: can("Sinisana") ? <FlureeTransact /> : null,
      },
    ],
  };
};

export default AdminRoutes;
