import DashboardIcon from "@mui/icons-material/Dashboard";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  permission: "Dashboard",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/admin/dashboard",
      icon: DashboardIcon,
      breadcrumbs: false,
      permission: "Dashboard",
    },
    {
      id: "recall",
      title: "Recall",
      type: "item",
      url: "/admin/recall",
      icon: ImagesearchRollerIcon,
      breadcrumbs: false,
      permission: "Recall",
    },
  ],
};

export default dashboard;
