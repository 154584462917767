import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

const flureeMenu = {
  id: "fluree",
  title: "Fluree",
  type: "group",
  permission: "Sinisana",
  children: [
    {
      id: "query",
      title: "Query",
      type: "item",
      url: "/admin/fluree/query",
      icon: QueryBuilderIcon,
      breadcrumbs: false,
    },
    {
      id: "transact",
      title: "Transact",
      type: "item",
      url: "/admin/fluree/transact",
      icon: CompareArrowsIcon,
      breadcrumbs: false,
    },
  ],
};

export default flureeMenu;
