import { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ButtonBase,
  Typography,
  Paper,
  Popper,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ClickAwayListener,
  Fade,
} from "@mui/material";
import Button from "@mui/material/Button";

import config from "../../../config";
import logo from "../../../assets/images/logo.png";

import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import AuthService from "../../../services/AuthService";
import { AuthContext, EntityContext } from "../../../App";
import SwitchEntityDialog from "../../switchEntity/SwitchEntityDialog";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authState, dispatch } = useContext(AuthContext);
  const { activeEntity, entityList } = useContext(EntityContext);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [settingsMenu, setSettingsMenu] = useState(false);

  const [switchEntityDialog, setSwitchEntityDialog] = useState(false);

  const anchorRef = useRef(null);

  const handleLogout = () => {
    AuthService.logout();
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);
    if (route && route !== "") {
      navigate(route);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setSettingsMenu(false);
  };

  const handleToggle = () => {
    setSettingsMenu((prevOpen) => !prevOpen);
  };

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box component="span" sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}>
          <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <img width={92} height={"auto"} src={logo} alt={"Sara-Bif Logo"} />
          </ButtonBase>
        </Box>
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              "&:hover": {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <MenuIcon />
          </Avatar>
        </ButtonBase>
      </Box>

      <Typography marginLeft={4} variant="h6">
        <Button
          variant="text"
          onClick={() => {
            setSwitchEntityDialog(true);
          }}
        >
          {activeEntity?.["Entity/Name"]}
        </Button>
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      <Box marginRight={4}>{authState.authUser?.name}</Box>

      {/* notification & profile */}
      <>
        <Chip
          sx={{
            height: "48px",
            alignItems: "center",
            borderRadius: "27px",
            transition: "all .2s ease-in-out",
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            '&[aria-controls="menu-list-grow"], &:hover': {
              borderColor: theme.palette.primary.main,
              background: `${theme.palette.primary.main}!important`,
              color: theme.palette.primary.light,
              "& svg": {
                stroke: theme.palette.primary.light,
              },
            },
            "& .MuiChip-label": {
              lineHeight: 0,
            },
          }}
          label={<SettingsIcon stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
          variant="outlined"
          ref={anchorRef}
          aria-controls={settingsMenu ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
        />
        <Popper
          placement="bottom-end"
          open={settingsMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 14],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  {/* <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}> */}
                  <Box sx={{ p: 2 }}>
                    <List
                      component="nav"
                      sx={{
                        width: "100%",
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "10px",
                        [theme.breakpoints.down("md")]: {
                          minWidth: "100%",
                        },
                        "& .MuiListItemButton-root": {
                          mt: 0.5,
                        },
                      }}
                    >
                      <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, 0, "/admin/account/settings")}
                      >
                        <ListItemIcon>
                          <SettingsIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                      </ListItemButton>

                      <ListItemButton selected={selectedIndex === 4} onClick={handleLogout}>
                        <ListItemIcon>
                          <LogoutIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                      </ListItemButton>
                    </List>
                  </Box>
                  {/* </MainCard> */}
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </>

      <SwitchEntityDialog visible={switchEntityDialog} setVisible={setSwitchEntityDialog} />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
