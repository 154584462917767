// assets
import ListAltIcon from "@mui/icons-material/ListAlt";
import QrCodeIcon from "@mui/icons-material/QrCode";
import StoreIcon from "@mui/icons-material/Store";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const settings = {
  id: "settings",
  title: "Settings",
  type: "group",
  permission: "Settings",
  children: [
    {
      id: "product",
      title: "Product",
      type: "item",
      url: "/admin/settings/products",
      icon: ListAltIcon,
      breadcrumbs: false,
    },
    {
      id: "supplier",
      title: "Supplier",
      type: "item",
      url: "/admin/settings/suppliers",
      icon: StoreIcon,
      breadcrumbs: false,
    },
    {
      id: "outlet",
      title: "Customer",
      type: "item",
      url: "/admin/settings/customer",
      icon: StoreIcon,
      breadcrumbs: false,
    },
    {
      id: "outlet",
      title: "Staff",
      type: "item",
      url: "/admin/settings/staff",
      icon: PersonIcon,
      breadcrumbs: false,
    },
    {
      id: "vehicle",
      title: "Vehicle",
      type: "item",
      url: "/admin/settings/vehicle",
      icon: LocalShippingIcon,
      breadcrumbs: false,
    },
    {
      id: "qrcode",
      title: "QR Code",
      type: "item",
      url: "/admin/settings/traceBatches",
      icon: QrCodeIcon,
      breadcrumbs: false,
      permission: "TraceCode",
    },
  ],
};

export default settings;
