import React, { useState, useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

// project imports
import Header from "./Header";
import { drawerWidth } from "../../../constants/theme";
// assets
import Sidebar from "./Sidebar";
import { AuthContext } from "../../../App";
import AuthService from "../../../services/AuthService";
import PageLoader from "../../loadable/PageLoader";

export const AdminLayoutContext = React.createContext();

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 20),
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
      padding: "16px",
      marginRight: "10px",
    },
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  }),
}));

// ==============================|| MAIN LAYOUT ||============================== //

const AdminLayout = () => {
  const { authState, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("lg"));

  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleLeftDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (!authState.isAuthenticated) {
      AuthService.checkAuth().then((res) => {
        if (res.status === "success") {
          dispatch({
            type: "LOGIN",
            payload: {
              authUser: res.data.auth,
              token: res.data.token,
            },
          });
        } else {
          navigate("/login");
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [authState]);

  useEffect(() => {
    setSidebarOpen(!matchDownMd);
  }, [matchDownMd]);

  return loading ? (
    <PageLoader />
  ) : (
    <AdminLayoutContext.Provider
      value={{
        setSidebarOpen,
      }}
    >
      <Box sx={{ display: "flex" }}>
        {/* <CssBaseline /> */}
        {/* header */}
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={1}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: sidebarOpen ? theme.transitions.create("width") : "none",
          }}
        >
          <Toolbar>
            <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
          </Toolbar>
        </AppBar>

        {/* drawer */}
        <Sidebar drawerOpen={sidebarOpen} drawerToggle={handleLeftDrawerToggle} />

        {/* main content */}
        <Main
          theme={theme}
          open={sidebarOpen}
          style={{ backgroundColor: "#f9f9f9", height: "100%", padding: 0, marginTop: "64px" }}
        >
          <Outlet />
        </Main>
      </Box>
    </AdminLayoutContext.Provider>
  );
};

export default AdminLayout;
