import { useContext } from "react";
import { useRoutes } from "react-router-dom";
import { EntityContext } from "../App";

// routes
import AdminRoutes from "./AdminRoutes";
import WebRoutes from "./WebRoutes";

// ==============================|| ROUTING RENDER ||============================== //
const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "/",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
};

export default function ThemeRoutes() {
  const { can } = useContext(EntityContext);

  return useRoutes([WebRoutes, AdminRoutes(can)], config.basename);
}
