import { lazy } from "react";

// project imports
import Loadable from "../components/loadable/Loadable";
// import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// Login
const Login = Loadable(lazy(() => import("../views/login/Login")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //
const MinimalLayout = () => (
  <div
    style={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    }}
  >
    <h4>
      PPES TERNAK<br></br>
      Admin Dashboard
    </h4>
    <p>
      <a href="/login">Login</a>
    </p>
  </div>
);
const AuthLogin3 = () => <div>Dashboard Default</div>;
const WebRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MinimalLayout />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/pages/register/register3",
      //   element: <AuthRegister3 />,
    },
  ],
};

export default WebRoutes;
