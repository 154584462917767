// assets
import BusinessIcon from "@mui/icons-material/Business";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SecurityIcon from "@mui/icons-material/Security";
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const settings = {
  id: "sinisana",
  title: "Sinisana",
  type: "group",
  permission: "Sinisana",
  children: [
    {
      id: "security",
      title: "Security",
      type: "item",
      url: "/admin/sinisana/security",
      icon: SecurityIcon,
      breadcrumbs: false,
    },
    {
      id: "entity",
      title: "Entity",
      type: "item",
      url: "/admin/sinisana/entities",
      icon: BusinessIcon,
      breadcrumbs: false,
    },
    {
      id: "account",
      title: "Account",
      type: "item",
      url: "/admin/sinisana/accounts",
      icon: AccountBoxIcon,
      breadcrumbs: false,
    },
  ],
};

export default settings;
