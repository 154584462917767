// assets
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import InputIcon from "@mui/icons-material/Input";

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const stockMovement = {
  id: "stockMovement",
  title: "Stock Movement",
  type: "group",
  permission: "StockMovement",
  children: [
    {
      id: "stockIn",
      title: "Stock In",
      type: "item",
      url: "/admin/stockIn",
      icon: InputIcon,
      breadcrumbs: false,
      permission: "StockIn",
    },
    {
      id: "stockOut",
      title: "Stock Out",
      type: "item",
      url: "/admin/stockOut",
      icon: LocalShippingIcon,
      breadcrumbs: false,
      permission: "StockOut",
    },
    {
      id: "stockReturn",
      title: "Stock Return",
      type: "item",
      url: "/admin/stockReturn",
      icon: TurnLeftIcon,
      breadcrumbs: false,
      permission: "StockReturn",
    },
  ],
};

export default stockMovement;
