import dashboard from "./dashboard";
import stockMovement from "./stockMovement";
import inventory from "./inventory";
import processing from "./processing";
import settings from "./settings";
import sinisana from "./sinisana";
import fluree from "./fluree";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, processing, stockMovement, inventory, settings, sinisana, fluree],
};

export default menuItems;
